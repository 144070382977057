import React, { useRef } from "react";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    gql,
    useQuery,
} from "@apollo/client";
import * as THREE from "three";
import { useState, useEffect } from "react";
import Globe from "react-globe.gl";
import GothamPro from "./fonts/Gotham Pro_Regular.json";

const client = new ApolloClient({
    uri: "https://letoyakutia.ru/graphql",
    cache: new InMemoryCache(),
});

export default function World() {
    return (
        <ApolloProvider client={client}>
            <GlobeElement />
        </ApolloProvider>
    );
}

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

function GlobeElement() {
    const prefix = isDev ? "" : "/wp-content/themes/betheme/build";
    const globeEl = useRef();

    const { data, loading, error } = useQuery(QUERY_GQL);
    const [geoData, setGeoData] = useState([]);
    const [hoverPolygon, setHoverPolygon] = useState([]);

    const [position, setPosition] = useState({ top: 0, left: 0 });

    const [pointPositions, setPointPositions] = useState([]);

    const onPolygonHover = (d) => {
        setHoverPolygon(d?.properties?.oktmo);
    };

    const worldToScreen = (worldPosition) => {
        const camera = window.globe.camera();
        const renderer = window.globe.renderer(); // Получаем рендерер через globe.renderer()
        const vector = new THREE.Vector3(
            worldPosition.x,
            worldPosition.y,
            worldPosition.z
        );

        // Преобразование в экранные координаты
        vector.project(camera);

        // Преобразование в координаты экрана
        const canvas = renderer.domElement;
        const rect = canvas.getBoundingClientRect();
        const widthHalf = rect.width / 2;
        const heightHalf = rect.height / 2;
     //   const widthHalf = 0.5 * canvas.width;
     //   const heightHalf = 0.5 * canvas.height;
        vector.x = vector.x * widthHalf + widthHalf;
        vector.y = -vector.y * heightHalf + heightHalf;

        return {
            x: vector.x,
            y: vector.y,
        };
    };
    const getScreenPos = (pointData) => {
        const pointPosition = window.globe.getCoords(
            pointData.lat,
            pointData.lng
        );
        return worldToScreen(pointPosition);
    };

    const getPortfolio = (name, filter) =>
        data?.portfolios.nodes.filter(
            (item) =>
                item.interactiveMapLocation.location == name &&
                item.interactiveMapLocation.type == filter
        );

    const [lastUpdate, setLastUpdate] = useState(0);
    useEffect(() => {
        window.globe = globeEl.current;
        const globe = globeEl.current;

        // Разрешаем зум и панорамирование
        const controls = globe.controls();

        controls.enableZoom = true;

        const loadAndSetData = async () => {
            try {
                // Загрузка данных для стран
                let data = await loadGeoJSON(
                    prefix + "/globe/ne_110m_admin_0_countries.geojson"
                );
                const countriesData = data.features.map((feature) => ({
                    ...feature,
                }));
                setGeoData((current) => [...current, ...countriesData]);

                data = await loadGeoJSON(prefix + "/globe/Russia2.geojson");
                const russiaData = data.features.map((feature) => ({
                    ...feature,
                    source: "russia",
                }));
                setGeoData((current) => [...current, ...russiaData]);

                data = await loadGeoJSON(prefix + "/globe/Yakutia2.geojson");
                const yakutiaData = data.features.map((feature) => ({
                    ...feature,
                    source: "yakutia",
                }));
                setGeoData((current) => [...current, ...yakutiaData]);
            } catch (error) {
                console.error("Error loading GeoJSON data:", error);
            }
        };
        loadAndSetData();

        const handleChange = () => {
            const now = performance.now();
            const throttleDelay = 16.66666666;
            if (now - lastUpdate > throttleDelay) {
                setPointPositions((current) =>
                    current?.map((pointData) => ({
                        ...pointData,
                        screenPos: getScreenPos(pointData),
                    }))
                );
                setLastUpdate(now);
            }
        };

        controls.addEventListener("change", handleChange);

        return () => {
            controls.removeEventListener("change", handleChange);
        };
    }, []);

    useEffect(() => {
        if (data?.interactiveMap) {
            let map = data?.interactiveMap;

            window.globe.pointOfView(map?.camera?.startPoint, 0);

            window.globe.startAnimation = () => {
                window.globe.pointOfView(
                    map?.camera?.endPoint,
                    map?.camera?.duration
                );

                setPosition({ top: -200, left: 0 });

                setTimeout(() => {
                    const points = map?.locations?.nodes?.map((pointData) => ({
                        ...pointData,
                        screenPos: getScreenPos(pointData),
                        projects: getPortfolio(pointData.name, "Проекты"),
                        objects: getPortfolio(pointData.name, "Объекты"),
                        plans: getPortfolio(pointData.name, "Мастер-планы"),
                    }));

                    setPointPositions(points);
                    console.log(points);
                    setTimeout(() => {
                        setPointsActive(true);
                    }, 200);
                }, map?.camera?.duration);
            };

            window.globe.startAnimation();
        }
    }, [loading]);

    const globeMaterial = new THREE.MeshBasicMaterial({ color: 0xebebeb });

    const [pointsActive, setPointsActive] = useState(false);

    const capColor = (feature) =>
        feature.properties?.oktmo && feature.properties?.oktmo === hoverPolygon
            ? data?.interactiveMap?.camera?.polygonHover
            : polygonCapColor(feature);

    const polygonAltitude = (feature) => {
        let altitude = data?.interactiveMap?.camera?.polygonAltitude;
        if (feature.source === "yakutia") {
            return altitude?.yakutia ?? 0.02;
        } else if (feature.source === "russia") {
            return altitude?.russia ?? 0.015;
        }
        return altitude?.world ?? 0.01;
    };

    const polygonSideColor = (feature) => {
        let color = data?.interactiveMap?.camera?.polygonSideColor;
        if (feature.source === "yakutia") {
            return color?.yakutia ?? "#FFFFFF00";
        } else if (feature.source === "russia") {
            return color?.russia ?? "#FFFFFF00";
        }
        return color?.world ?? "#FFFFFF00";
    };

    const polygonCapColor = (feature) => {
        let color = data?.interactiveMap?.camera?.polygonCapColor;
        if (feature.source === "yakutia") {
            return color?.yakutia ?? "#FFFFFF00";
        } else if (feature.source === "russia") {
            return color?.russia ?? "#FFFFFF00";
        }
        return color?.world ?? "#FFFFFF00";
    };

    const polygonStrokeColor = (feature) => {
        let color = data?.interactiveMap?.camera?.polygonStrokeColor;
        if (feature.source === "yakutia") {
            return color?.yakutia ?? "#FFFFFF00";
        } else if (feature.source === "russia") {
            return color?.russia ?? "#FFFFFF00";
        }
        return color?.world ?? "#FFFFFF00";
    };

    const onClick = (point) => {
        console.log(point);
        if (window.openMapModal) {
            window.openMapModal(point);
        }
    };

    const [projectsFilter, setProjectsFilter] = useState(true);
    const [objectsFilter, setObjectsFilter] = useState(false);
    const [planFilter, setPlanFilter] = useState(false);

    useEffect(() => {}, [projectsFilter, objectsFilter, planFilter]);

    let pointsSet = new Set();
    let emptyPointsSet = new Set();
    pointPositions?.map((point) => {
        if (projectsFilter && point.projects.length) {
            pointsSet.add(point);
        } else if (objectsFilter && point.objects.length) {
            pointsSet.add(point);
        } else if (planFilter && point.plans.length) {
            pointsSet.add(point);
        } else {
            emptyPointsSet.add(point);
        }
    });

    const points = [...pointsSet];
    const emptyPoints = [...emptyPointsSet];

    return (
        <ApolloProvider client={client}>
            <div className="relative h-[803px] overflow-hidden interactive-map">
                <div
                    className="absolute w-full h-full transition-all duration-[4s]"
                    style={{
                        top: `${position.top}px`,
                        left: `${position.left}px`,
                    }}
                >
                    <div className="absolute left-[50%] translate-x-[-50%]">
                        <Globe
                            onZoom={(d) => console.log()}
                            width="1920"
                            height="1500"
                            backgroundColor="#00000000"
                            ref={globeEl}
                            globeMaterial={globeMaterial}
                            polygonsData={geoData}
                            polygonCapColor={capColor}
                            polygonSideColor={polygonSideColor}
                            polygonStrokeColor={polygonStrokeColor}
                            polygonAltitude={polygonAltitude}
                            onPolygonHover={onPolygonHover}
                            polygonsTransitionDuration={0}
                            animateIn={false}
                            showGraticules={true}
                            labelTypeFace={GothamPro}
                            labelResolution={2}
                        />
                    </div>
                    <div
                        className={`${
                            pointsActive ? "active" : ""
                        } group absolute w-[1920px] left-[50%] translate-x-[-50%] pointer-events-none`}
                    >
                        {emptyPoints.map((point, index) => (
                            <div
                                onClick={() => onClick(point)}
                                key={index}
                                className="opacity-30 absolute translate-y-[-100%] pointer-events-auto cursor-pointer"
                                style={{
                                    left: point.screenPos.x,
                                    top: point.screenPos.y,
                                }}
                            >
                                <div className="group-[.active]:scale-100 transition-transform duration-200 scale-0 absolute left-0 translate-x-[-50%] bottom-0 translate-y-[50%] rounded-full bg-white/70 w-2 h-2 p-0.5">
                                    <div className="rounded-full w-full h-full bg-[#FF7161]" />
                                </div>
                                {!point?.hideLabel && (
                                    <div className="group-[.active]:scale-100 origin-bottom-left transition-transform delay-200 duration-300 scale-0 bg-white/70 px-1 py-0.5 rounded-md font-[Arial] text-xs">
                                        {point.name}
                                    </div>
                                )}
                            </div>
                        ))}
                        {points.map((point, index) => (
                            <div
                                onClick={() => onClick(point)}
                                key={index}
                                className="absolute translate-y-[-100%] pointer-events-auto cursor-pointer"
                                style={{
                                    left: point.screenPos.x,
                                    top: point.screenPos.y,
                                }}
                            >
                                <div className="group-[.active]:scale-100 transition-transform duration-200 scale-0 absolute left-0 translate-x-[-50%] bottom-0 translate-y-[50%] rounded-full bg-white/70 w-2 h-2 p-0.5">
                                    <div className="rounded-full w-full h-full bg-[#FF7161]" />
                                </div>
                                {!point?.hideLabel && (
                                    <div className="group-[.active]:scale-100 origin-bottom-left transition-transform delay-200 duration-300 scale-0 bg-white/70 px-1 py-0.5 rounded-md font-[Arial] text-xs">
                                        {point.name}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <ToggleSwitches
                    projectsFilter={projectsFilter}
                    setProjectsFilter={setProjectsFilter}
                    objectsFilter={objectsFilter}
                    setObjectsFilter={setObjectsFilter}
                    planFilter={planFilter}
                    setPlanFilter={setPlanFilter}
                />
            </div>
        </ApolloProvider>
    );
}

const ToggleSwitches = ({
    projectsFilter,
    setProjectsFilter,
    objectsFilter,
    setObjectsFilter,
    planFilter,
    setPlanFilter,
}) => {
    return (
        <div className="flex flex-col gap-6 p-16 absolute left-0 bottom-0 w-[322px] bg-[#F5F5F5] h-fit">
            <ToggleOption
                label="Проекты"
                isActive={projectsFilter}
                onClick={() => setProjectsFilter(!projectsFilter)}
            />
            <ToggleOption
                label="Объекты"
                isActive={objectsFilter}
                onClick={() => setObjectsFilter(!objectsFilter)}
            />
            <ToggleOption
                label="Мастер-планы"
                isActive={planFilter}
                onClick={() => setPlanFilter(!planFilter)}
            />
        </div>
    );
};

const ToggleOption = ({ label, isActive, onClick }) => {
    return (
        <div
            onClick={onClick}
            style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
            }}
        >
            <div className="w-9 h-5 bg-white rounded-full relative">
                <div
                    className={`absolute rounded-full top-0.5 w-4 h-4 transition-all ${
                        isActive
                            ? "bg-[#FF7161] left-[17px]"
                            : "bg-[#B3B3B3] left-[3px]"
                    }`}
                ></div>
            </div>
            <span>{label}</span>
        </div>
    );
};

function loadGeoJSON(url) {
    return fetch(url).then((res) => {
        if (!res.ok) {
            throw new Error("Network response was not ok");
        }
        return res.json();
    });
}

const QUERY_GQL = gql`
    query NewQuery {
        interactiveMap {
            camera {
                startPoint {
                    lat
                    lng
                    altitude
                }
                endPoint {
                    lat
                    lng
                    altitude
                }
                duration
                polygonSideColor {
                    world
                    russia
                    yakutia
                }
                polygonStrokeColor {
                    world
                    russia
                    yakutia
                }
                polygonCapColor {
                    world
                    russia
                    yakutia
                }
                polygonAltitude {
                    world
                    russia
                    yakutia
                }
                polygonHover
            }
            locations {
                nodes {
                    lat
                    lng
                    hideLabel
                    name
                }
            }
        }
        portfolios(first: 500) {
            nodes {
                title
                interactiveMapLocation {
                    location
                    mestopolojenie
                    tipologiya
                    type
                }
                link
            }
        }
    }
`;

/*
<script>
  window.mapFilters = {
    projects: true,
    objects: false,
    masterPlan: false,
    update(filterName, value) {
      this[filterName] = value;
      window.dispatchEvent(
        new CustomEvent("mapFilterChanged", { detail: { filterName, value } })
      );
    }
  };
</script>

useEffect(() => {
    const onFilterChange = (event) => {
      console.log("Фильтр изменён:", event.detail);
      // Здесь можно обновлять локальное состояние или перерендеривать карту
    };
  
    window.addEventListener("mapFilterChanged", onFilterChange);
  
    return () => window.removeEventListener("mapFilterChanged", onFilterChange);
  }, []);
  
  window.mapFilters.update("projects", false);

  */